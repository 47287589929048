.navigator{
    position: fixed;
    top: 130px;
    left: 0;
    width: 10%;
    z-index: 999;
    text-align: center;

    display: flex;
    flex-direction: column;
}

.navigator .nav-item a{
    line-height: 40px;
    color: #fff !important;
    background-color: rgba(0,0,0,.5);
    border: none;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    width: 100px;
    display: block;

    text-decoration: none !important;

    transition: all .3s ease-in-out;

    /* text decoration vertocal */

}

.nav-item a {
    color: inherit; 
    text-decoration: none;
}

.nav-item a:hover{
    background-color: rgba(0,0,0,.8);
}

.nav-item a.active{
    background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
}


@media(max-width: 600px){
    .navigator {
        position:fixed;
        width: 100%;
        flex-direction: row;
        flex-justify: space-evenly;
        top: 80px;
    }


    .navigator .nav-item{
        width:80%;
        margin: 0;   
    }

    .navigator .nav-item a{
        width:100%;
        margin: 0;   
    }
}