.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1999999;
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */

    overflow-y: hidden;
  }


  
  .modal {
    background-color: rgba(0,0,0,0.7); /* Black background */
    color: #fff;  /* White text */
    padding: 10px;
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 4px;
    max-width: 700px;
    width: 100%;
    z-index: 99998;
    top: 10%;
    position: absolute;
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */

  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: white;
    z-index: 99999;

  }
  
  .modalContent {
    margin-top: 20px;
  }


@media(max-width: 600px){
    .modal {
      position: relative;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 10px;
    }

    .modalOverlay{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 0px;
    }


  .closeButton {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background: gray;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: white;
    z-index: 999999;
    height: 40px;
    padding: 10px
  }
  
  .modalContent {
    margin-top: 40px;
  }
}