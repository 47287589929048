@font-face {
  font-family: fortunate;
  src: url('../public/fonts/fortunates-december/Fortunates December.ttf');
}

@font-face {
  font-family: quicksand-light;
  src: url('../public/fonts/quicksand/Quicksand-Light.ttf');
}

.App {
  overflow: hidden;
  font-family: quicksand-light;
}
body {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
  backdrop-filter: blur(10px);
}

.parallax-content {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: calc(10px + 2vmin);
  color: white;

  top: 0;
  left: 0;
  right: 0;


  background: rgba(0, 0, 0, 0.2); /* Black background with opacity */
  backdrop-filter: blur(10px);

  z-index: 99999;
  height: 80px;
}

.parallax-content.gallery_mode {
  position: fixed;
  top: 0;
  opacity: 0.8;  
  z-index: 99996;
  padding: 0;
  margin: 0
}
.parallax-content > h3 {
  padding: 0;
  font-size: 15px;
  margin: 0;
}


.parallax-content > h1 {
  padding: 0;
  font-size: 35px;
  margin: 0;  
  font-family: fortunate;
}

@media(max-width: 600px){
  

}

.info {
  position: absolute;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: #f1f1f1;
  backdrop-filter: blur(10px);
  text-align: left;
  display: flex;
  justify-content: center;
  flex-flow: column;

  bottom: 15%;
  width: 50%;
  left: 0;

  z-index: 99996;
}


.info.gallery_mode {
  display: none;
}

@media(max-width: 600px){
  .info {
    padding: 10px;
    background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
    color: #f1f1f1;
    backdrop-filter: blur(10px);
    text-align: left;
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */


    position: unset;
    width: 95%;
    left: 0;
    z-index: unset;
  }


  .info.gallery_mode {
    display: none;
  }

}

.footer{
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: static;
  bottom: 0;
  width: 100%;
}

.footer p {
  text-align: justify;
  padding: 10px;
}
.footer a {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.socialLinks, .contactInfo {
  margin-bottom: 10px;
}

.socialLinks i {

  font-size: 30px;
}

@media(max-width: 600px){

  .footer{

    position:unset;
    bottom: 0;
    width: 100%;
  }
}

.fullscreen_siwtcher{
    position: fixed;
    display: inline-block;

    left: 0;
    color: white;
    z-index: 999999;
    cursor: pointer;
    padding: 5px;

    font-size: 20px;



    background: rgba(0.7, 0, 0, 0.5); /* Black background with opacity */
    backdrop-filter: blur(10px);
    border-radius: 5px;
}


@media(max-width: 600px){
  .fullscreen_siwtcher {
    top: 10px;
    left: 5px;
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }
}