.contactForm {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,0.5); /* Black background */
    color: #fff;  /* White text */
    padding: 10px;
    bottom: 15%;
    right: 0;
    min-width: 350px;
    backdrop-filter: blur(10px);
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */
}

.contactForm.gallery_mode {
    position:initial;
    width: auto;
    background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */
}


.contactForm > label {
    text-align: left;
}
  
.contactFormField {
    margin: 10px;
    padding: 10px;
    width: 80%;
    color: #fff;  /* White text */
    background-color: #444;  /* Black background */
    border: 1px solid #fff; /* White border */
    border: none;  /* No border */
}

.contactFormField:focus {
    outline: none; /* Remove focus outline */
}
  
.contactFormSubmit {
    margin: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}


.contactForm p {
    text-align: center;
    font-style: italic;
    font-size: 20px;
}
@media(max-width: 600px){
    .contactForm {
        position:initial;
        width: auto;
        background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
        transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */
    }
}
  /* Add here the mobile part */

