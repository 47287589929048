
.product-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;


}


.product-container{
    padding: 10px;
    top: 400px;
    height: 40%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    color: #f1f1f1;
    backdrop-filter: blur(10px);
}

.product-container.gallery_mode {
  display: none;
}

.product-container h1 {
    color: white;
    margin: 0px;
}

@media(max-width: 600px){
    .product-container{
      position: unset; 
      width: 100%;
      padding: 0;

      background: rgba(0, 0, 0, 0.9); /* Black background with opacity */
    }

    .product-list {
        display: flex;
        flex-flow:unset;
        justify-content: unset;
        overflow-x: auto;
        height: 60vh;
        align-items: center;

      right: 0%;
    }


    .product-container.gallery_mode {
      display: none;
    }


    .product-list .product {
        margin-right: 15px;
        min-width: 300px;
        margin-top: 0px;
    }
    .product-lis::-webkit-scrollbar {
        display: none;
    }
  }
  

.product {
    border-radius: 4px;
    padding: 16px;
    max-width: 300px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


    background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    color: #f1f1f1;
    backdrop-filter: blur(10px);
  }
  
  .product-image-container {
    width: 100%;
    text-align: center;
  }
  
  .product-image {
    max-width: 100%;
    height: auto;
  }
  
  .product-details {
    margin-top: 10px;
  }
  
  .product-name {
    font-size: 1.5rem;
  }
  
  .product-price {
    font-size: 1.2rem;
    margin-top: 5px;
  }
  
  .product-description {
    font-size: 1rem;
    margin-top: 10px;
  }