.slider-container {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0%;
    bottom: 0%;
    overflow: hidden;    
    left: 0;
    right: 0;
  }
  
  
  .after-image.hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }


  .after-image.show {
    opacity: 1 !important; /* Change opacity to 1 to reveal the container */    
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }


  .before-image.hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }


  .before-image.show {
    opacity: 1 !important; /* Change opacity to 1 to reveal the container */    
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }
  
  .before-image, .after-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .slider-handle {
    position: absolute;
    top: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff);
    cursor: ew-resize;
  }


@media(max-width: 600px){
  .slider-container {
    position: relative;
    width: 100%;
    height: 50vh;
    top: 0;
    overflow: hidden;    
    left: 0;
    right: 0;
  }


.slider-container.gallery_mode {
    height: 100vh;
  }
}

.bullets {
  display: flex;
  position: relative;
  top: 97%;
  flex-direction: row;
  justify-content: center;
  z-index: 9999999;
}

.bullets .bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.5;
  border: 1px solid;
  border-color: rgba(0,0,0,0.3);
}


@media(max-width: 600px){
  .bullet {
    width: 50px;
    height: 50px;
    display:none;
  }
  .bullets {
    display: none;
  }
}

.bullets .bullet.active {
  opacity: 1;
}