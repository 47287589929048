.store {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    top: 130px;
    position: relative;
    min-height: 100vh;

    padding: 100px;
    padding-bottom: 160px;
    padding-top: 0px;

}

.store .product {
    width: 40%;
    padding: 10px;
    box-sizing: border-box;
    max-height: 400px;

}

.store .product .images .default {
    max-width: 250px;
    max-height: 250px;
    padding: 5px;
}


@media(max-width: 600px){
    .store .product {
        width: 90%;
    }

    .store .product .images .default {
        padding: 0;
    }
    .store {

        margin-bottom: 150px;
        height: unset;

        padding: 2px;
        padding-bottom: 60px;
        padding-top: 0px;
    }
}

.hyr-title {
    color: white;
    width: 100%;
    padding: 10px;
    padding-top: 130px;
    margin-bottom: -90px;
}

.hyr-title2{

    color: white;
    width: 100%;
    padding: 2px;
    padding-top: 70px;
    margin-bottom: -50px;
}

.hyr-container .contactFormField{
    margin-top: 100px
}

.alert {
    color: orange;
}