.languageSelector {
    position: fixed;
    display: inline-block;
    right: 0;
    z-index: 1999999;
  }
  
  .languageButton {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .languageDropdown {
    position: absolute;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    color: #f1f1f1;
    backdrop-filter: blur(10px);
    z-index: 1;
  }
  
  .languageItem {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
  
  .languageItem:hover {
    background-color: gray;
  }


  .languageItem{
    display: flex;
  }
  
  .language-img {
    width: 30px;
  }

  label {
    color: white;
    vertical-align: middle;
    padding: 5px;
    cursor: pointer;
  }


@media(max-width: 600px){
  .languageSelector {
    right: 10px;
    top: 10px;
    transition: all 0.5s ease-in-out; /* Smooth transition effect for opacity */
  }
}