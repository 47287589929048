.hyr{
    display: inline-block;
    background: white;
    position: absolute;

    border-radius: 50%;
    z-index: 999998;

}

.hyr2{
    display: inline-block;
    padding: 5px;
    border: 1px solid white;
    position: absolute;
    border-radius: 50%;
    margin: -6px;

    animation: zoom-in-zoom-out 5s ease infinite;
}


@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.3, 1.3);
    }
    100% {
      transform: scale(1, 1);
    }
  }

.hyr-info {
    color: white;
    position: absolute;

    text-align: left;
    margin-top: -40px;
    margin-left: -50px;
    padding: 5px;
    border-radius: 5px;

    background: rgba(0.7, 0, 0, 0.5); /* Black background with opacity */
    backdrop-filter: blur(10px);
    z-index: 999999;
}

.hyr-info > * {
    padding: 0;
    margin: 0;
}